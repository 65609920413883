<template>
  <div class="master">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    <router-view/>
    </div> -->
    <Navbar name="Squad-Builder" />
    <div class="container">
      <router-view></router-view>
    </div>
    <Footer />
  </div>
</template>

<script>
//importing bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/core/Navbar.vue";
import Footer from "./components/core/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  head: {
    title: {
      inner: "SquadWizard",
    },
    // Meta tags
    meta: [
      { name: "application-name", content: "Squad Builder" },
      { name: "description", content: "A WebApp to build Soccer Squads" }, // id to replace intead of create element
    ],
    link: [
      {
        href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://cdnjs.cloudflare.com/ajax/libs/mdb-ui-kit/3.6.0/mdb.min.css",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@800&display=swap",
        rel: "stylesheet",
      },
      {
        href: "https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap",
        rel: "stylesheet",
      }
    ],
  },
  mounted() {
    this.$on("fresh-players", () => {
      console.log("fired");
    });
  },
};
</script>

<style scoped>
div.master {
  background-color: #f9f9f9 !important;
}
</style>