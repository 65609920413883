<template>
  <footer class="bg-light text-center text-lg-start mt-5">
    <!-- Copyright -->
    <div class="text-center p-3 footer-box" style="background-color: rgba(0, 0, 0, 0.2);">
      © 2021 - <strong>SquadWizard</strong> - Versione 1.3b<br>
      Realizzato con <span :class="[crudStatus ? 'gem-color' : 'gem-bn']" @click="switchCrud"><i class="fas fa-gem gem-icon"></i></span> <strong>Vue.js</strong>
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
  },
  data() {
    return {
      crudStatus: false,
    }
  },
  methods: {
    switchCrud() {
      this.$root.$emit("switch-crud");
      this.crudStatus = !(this.crudStatus);
    }
  }
}
</script>

<style>
  .footer-box, .footer-box a {
    color: grey;
  }
  .gem-icon {
    cursor: pointer;
  }
  .gem-color {
    color: #1baeaa !important;
  }
  .gem-bn {
    color: grey !important;
  }
</style>