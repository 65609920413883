import { required, email, minLength, maxLength } from 'vuelidate/lib/validators';

export const login = () => ({
	form: {
		email: { required, email },
		password: { required }
	}
});

export const register = () => ({
	form: {
		first_name: { required, minLength: minLength(3), maxLength: maxLength(20) },
		last_name: { required, minLength: minLength(3), maxLength: maxLength(20) },
		email: { required, email },
		password: { required, minLength: minLength(4) }
	}
});
