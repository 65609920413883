<template>
  <tr :class="{ 'edit-row': editMode, 'add-row': !editMode }">
    <td v-for="field in fields" :key="field.key">
      <!-- input text/number -->
      <Input
        v-if="field.type == 'text' || field.type == 'number'"
        :value="form[field.key]"
        @input="(v) => (form[field.key] = v)"
        :type="field.type"
        :placeholder="field.placeholder"
        :vuelidate="$v.form[field.key]"
        :showError="validationErr"
        addedClasses="width-150"
      />
      <!-- Select -->
      <select
        v-else-if="field.type == 'select'"
        :value="form[field.key]"
        @input="(e) => (form[field.key] = e.target.value)"
        class="form-select form-select-sm width-100"
        aria-label="Default select example"
        aria-placeholder="Livello"
      >
        <option selected>Selez. {{ field.placeholder }}</option>
        <option
          v-for="item in field.data"
          :key="`it${item}`"
          :value="item"
          v-text="item"
        />
      </select>
      <!-- Image Upload -->
      <ImageUpload
        :current="image"
        v-else-if="field.type == 'image-upload'"
        v-model="form.file"
      />
    </td>
    <!-- Actions -->
    <td>
      <!-- Deleted :text="editMode ? 'Applica' : 'Add'" -->
      <Button
        @click="submit(() => $emit(editMode ? 'edit' : 'add', form))"
        color="success"
        classes="me-1 btn-floating btn-sm"
        iconFontawesome="fa-check"
      />
      <Button
        @click="$emit('close')"
        color="danger"
        classes="btn-floating btn-sm"
        iconFontawesome="fa-times"
      />
    </td>
  </tr>
</template>

<script>
import Button from "@/components/UI/Button.vue";
import Input from "@/components/UI/Input.vue";
import ImageUpload from "@/components/UI/ImageUpload.vue";
import FormValidate from "@/mixins/form-validate.js";
import { add as validations } from "@/validations/player.js";
import fields from "./fields";
export default {
  mixins: [FormValidate],
  props: ["data", "image"],
  data: () => ({
    fields,
    form: {
      name: "",
      role: "N/D",
      value: "",
      file: null,
    },
  }),
  computed: {
    editMode() {
      return !!this.data;
    },
  },
  watch: {
    data() {
      this.setValues();
    },
  },
  mounted() {
    this.setValues();
  },
  methods: {
    setValues() {
      this.form = this.data || {
        name: "",
        role: "N/D",
        value: 0.25,
        file: null,
      };
    },
  },
  validations: validations(),
  components: { ImageUpload, Button, Input },
};
</script>
<style lang="scss" scoped>
.add-row {
  background-color: #edffed;
}
.edit-row {
  background-color: #e6f2fb;
}
.width-100 {
  min-width: 100px !important;
}
</style>