export default [
  {
    key: "file",
    placeholder: "Immagine Gioc.",
    note: "Immagine Gioc.",
    type: "image-upload",
  },
  {
    key: "name",
    placeholder: "Nome Gioc.",
    note: "Nome Gioc.",
    type: "text",
  },
  {
    key: "value",
    placeholder: "Valore",
    note: "Valore Gioc.",
    type: "select",
    data: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3],
  },
  {
    key: "role",
    placeholder: "Ruolo",
    note: "Ruolo Gioc.",
    type: "select",
    data: ["N/D", "POR", "DIF", "CEN", "ATT"],
  },
];
