<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="referID"
    ref="modalMDB"
    tabindex="-1"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-light">
          <i
            v-show="iconFontawesome"
            :class="`me-2 fas ${iconFontawesome}`"
          ></i>
          <h5 class="modal-title" id="registerModalLabel" v-text="title" />
          <button
            ref="closeBtn"
            type="button"
            class="btn-close"
            data-mdb-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div class="modal-footer bg-light">
          <button
            type="button"
            class="btn btn-secondary"
            data-mdb-dismiss="modal"
          >
            <i class="far fa-times-circle"></i> 
            Chiudi
          </button>
          <Button
            @click="$emit('submit')"
            :text="submitText"
            :loading="submitLoading"
            :isDisabled="submitDisable"
            iconFontawesome="fa-check"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/UI/Button";
export default {
  props: {
    referID: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
    submitDisable: {
      type: Boolean,
      default: false,
    },
    iconFontawesome: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$refs.closeBtn.click();
    },
  },
  components: { Button },
};
</script>

<style>
</style>