<template>
  <div class="mb-3">
    <input
      :value="value"
      @input="(e) => $emit('input', e.target.value)"
      :type="type || 'text'"
      :class="[
        'form-control',
        'form-control-sm',
        addedClasses,
        showError && error.status && 'error-input',
      ]"
      :placeholder="placeholder"
    />
    <div class="add-hint" v-text="note" />
    <div class="invalid-feedback" v-if="showError" v-text="error.text" />
    <div
      class="invalid-feedback"
      v-if="errorNote && errorNote.con"
      v-text="errorNote.text"
    />
  </div>
</template>

<script>
export default {
  props: [
    "value",
    "vuelidate",
    "type",
    "placeholder",
    "note",
    "showError",
    "errorNote",
    "addedClasses"
  ],
  computed: {
    error() {
      const allErrs = [];
      const errs = {
        required: "Questo campo è richiesto!",
        email: "Questo campo deve essere un'e-mail!",
        maxLength: `Questo campo non può eccedere i ${this.vuelidate.$params.maxLength?.max} caratteri!`,
        minLength: `Questo campo deve contentere minimo ${this.vuelidate.$params.minLength?.min} caratteri!`,
      };
      Object.keys(this.vuelidate).forEach((key) => {
        if (key[0] == "$") return;
        if (this.vuelidate[key] === false)
          allErrs.push(errs[key] || "Errore di Validazione");
      });
      return { text: allErrs[0], status: !!allErrs.length };
    },
  },
};
</script>

<style lang="scss" scoped>
.add-hint {
  font-size: 12px;
  color: grey;
}
.invalid-feedback {
  position: static;
  display: block !important;
  margin-top: 0;
}
.error-input {
  border: 1px solid #f93154;
}
.width-150 {
  min-width: 150px !important;
  height: 29.5px;
}
</style>