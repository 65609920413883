<template>
  <div class="container mt-4">
    <div class="col-12 text-center players-num">Reimposta Partita:</div>
    <div class="offset-3 col-6 text-center">
      <select
        class="sel-players form-select form-select-sm"
        aria-label=".form-select-sm example"
        name="sel-players"
        @change="setPlayers"
      >
        <option value="2">Gabbione (2 vs 2)</option>
        <option value="4">Futsal (4 vs 4)</option>
        <option value="5">Calcetto (5 vs 5)</option>
        <option value="6" selected>Calcetto (6 vs 6)</option>
        <option value="7">Calcetto (7 vs 7)</option>
        <option value="8">Calciotto (8 vs 8)</option>
        <option value="11">Calcio (11 vs 11)</option>
      </select>
    </div>
    <div class="col-12 text-center">
      <button
        class="btn btn-sm shadow-sm btn-secondary mt-2"
        @click="resetPlayers"
      >
        <i class="fas fa-broom"></i> Reset Modulo
      </button>
    </div>
    <form v-on:submit.prevent="getOdds">
      <div class="row">
        <div class="col-md-6 border mt-4 team-box shadow-sm">
          <div
            class="
              text-center
              mb-3
              fw-bold
              py-2
              border-bottom
              rounded
              text-white
              mt-3
              shadow-sm
            "
            style="background-color: #20c997"
          >
            <i class="fas fa-users"></i> Squadra A
          </div>
          <hr />
          <player
            @added-player="addedPlayer"
            @selected-player="selectedPlayer"
            @remove-player="removedPlayer"
            @val-change="changedVal"
            @move-player="movePlayer"
            :players-db="playersDb"
            :players-number="playersNumber"
            :added-players="addedPlayers"
            :reset-data="resetData"
            :is-team-full="isTeamFull"
            :moving-arrows="selectedPlayerIdA == selectedPlayerIdB"
            v-for="index in playersNumber"
            :key="`playerA${index}`"
            :ref="`playerA${index}`"
          />
          <div class="text-center fw-bold py-2">
            <i class="fas fa-futbol"></i> Parziale A:
            <div class="partial-score partial-green border-top">
              {{ partialA }} pts
            </div>
          </div>
        </div>
        <div class="col-md-6 border mt-4 team-box shadow-sm">
          <div
            class="
              text-center
              mb-3
              fw-bold
              py-2
              border-bottom
              rounded
              text-white
              mt-3
              shadow-sm
            "
            style="background-color: #008bb7"
          >
            <i class="fas fa-users"></i> Squadra B
          </div>
          <hr />
          <player
            @added-player="addedPlayer"
            @selected-player="selectedPlayer"
            @remove-player="removedPlayer"
            @val-change="changedVal"
            @move-player="movePlayer"
            :players-db="playersDb"
            :players-number="playersNumber"
            :added-players="addedPlayers"
            :reset-data="resetData"
            :is-team-full="isTeamFull"
            :moving-arrows="selectedPlayerIdA == selectedPlayerIdB"
            v-for="index in playersNumber"
            :key="`playerB${index}`"
            :ref="`playerB${index}`"
          />
          <div class="text-center fw-bold py-2">
            <i class="fas fa-futbol"></i> Parziale B:
            <div class="partial-score partial-blue border-top">
              {{ partialB }} pts
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center my-4">
        <button
          type="button"
          class="btn btn-sm shadow-sm btn-primary"
          @click="switchPlayers"
          :disabled="!(isTeamFull && selectedPlayerIdA && selectedPlayerIdB)"
        >
          <i class="fas fa-exchange-alt"></i> Scambia Gioc.
        </button>
      </div>
      <div class="offset-3 col-6 text-center">
        <strong>{{ addedPlayers.length }}</strong> /
        <strong>{{ playersNumber * 2 }}</strong> giocatori.
        <b-progress
          :value="addedPlayers.length"
          :max="playersNumber * 2"
          height="1rem"
          animated
        ></b-progress>
        <small class="add-hint" v-show="!isTeamFull">Compila la griglia!</small>
        <small class="add-hint" v-show="isTeamFull">Ora puoi simulare!</small>
      </div>
      <div class="col-12 text-center mt-3">
        <button type="submit" class="btn btn-success" :disabled="!isTeamFull">
          <div class="spinner-border spinner-border-sm" v-if="loading"></div>
          <span v-if="loading"> Abracadabra...</span>
          <span v-show="!loading"
            ><i class="fas fa-hat-wizard"></i> Simulazione</span
          >
        </button>
      </div>
    </form>
    <div class="row" v-if="isTeamFull && showOddsArea">
      <div class="col-sm-6 mt-4">
        <div class="card border">
          <div class="card-body">
            <h5 class="card-title text-center color-primary">
              <i class="fas fa-chart-pie"></i> Pronostico
            </h5>
            <hr />
            <div class="card-text text-center">
              <small class="add-hint">Probabilità Squadra Vincente:</small>
              <b-progress
                show-value
                striped
                :max="max"
                class="my-2"
                height="1rem"
              >
                <b-progress-bar
                  variant="success"
                  :value="oddsA"
                ></b-progress-bar>
                <b-progress-bar
                  variant="primary"
                  :value="oddsB"
                ></b-progress-bar>
              </b-progress>
              <small class="add-hint d-flex justify-content-between">
                <span>Squadra A</span>
                <span>Squadra B</span>
              </small>
              <hr />
              <div class="text-center">
                Differenza Punteggi:
                <strong>{{ matchStats.partialDiff }} pts</strong>
              </div>
              <div class="text-center" v-if="partialA != partialB">
                Pro <strong>Squadra {{ matchStats.strongerTeam }}</strong>
              </div>
              <div class="text-center">
                Giudizio Partita: <strong>{{ matchStats.matchWord }}</strong>
              </div>
              <div class="text-center">
                Livello Partita:
                <i
                  v-for="(i, index) in 5"
                  :key="`mq${index}`"
                  :class="`${
                    matchStats.matchQuality < i ? 'far' : 'fas'
                  } fa-star quality-star`"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mt-4">
        <div class="card border">
          <div class="card-body">
            <h5 class="card-title text-center">
              <i class="far fa-lightbulb"></i> Consigli
            </h5>
            <hr />
            <div class="card-text text-center">
              <div>
                <strong>Suggerimento:</strong>
                <div class="hints">"{{ randArray(hints) }}"</div>
              </div>
              <hr />
              <div>
                <strong>Curiosità:</strong>
                <div class="facts">"{{ randArray(facts) }}"</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Player from "@/components/player/Player.vue";
import { fetch } from "@/services/players.js";
import { mapState } from "vuex";
export default {
  components: { Player },
  data() {
    return {
      loadingPush: false,
      newPlayer: {
        role: "N/D",
        value: 0,
      },
      playersNumber: 6,
      playersDb: [],
      addedPlayers: [],
      selectedPlayerIdA: "",
      selectedPlayerIdB: "",
      selectedPlayerObjA: {},
      selectedPlayerObjB: {},
      movingPlayerIdFrom: "",
      movingPlayerIdTo: "",
      movingPlayerFrom: {},
      movingPlayerTo: {},
      playerMoving: false,
      partialA: 0,
      partialB: 0,
      matchScore: 0,
      matchStats: {
        partialDiff: 0,
        strongerTeam: "",
        matchWord: "",
        matchQuality: 0,
      },
      teamFull: false,
      hints: [
        "Se le squadre sono leggermente squilibrate, prova ad invertire i 2 portieri.",
        "Dividi sempre i 2 giocatori più forti ed i 2 più deboli nella griglia di gioco.",
        "La predizione di SquadWizard non può tenere in conto l'organizzazione in campo o lo stato di forma dei giocatori.",
        "Nel calcetto 6vs6, il 3-1-1 è il modulo più solido ed utilizzato.",
        "In caso di evidente squilibrio fin dai primi minuti della partita, effettua subito un cambio che inverta la tendenza.",
        "Lo scarto fra i punteggi è più influente se i giocatori in campo sono pochi, ha minor impatto in una partita di calcio a 11.",
        "Una squadra in cui ogni giocatore è a proprio agio nel suo ruolo possiede virtualmente un surplus nel punteggio.",
        "Non esitare ad aggiornare i punteggi dei giocatori in base allo stato di forma o eventuali infortuni.",
        "In caso di assenza di portieri fissi, l'esito della partita diventa ancora più incerto.",
        "Se è presente un solo portiere di ruolo, fai in modo che la squadra senza portiere fisso abbia circa 1 punto in più.",
        "Un portiere eccessivamente forte potrebbe fare la differenza in una partita di calcetto, fai in modo di inserirlo nella squadra sfavorita.",
      ],
      facts: [
        "Il pareggio in una partita di calcetto amatoriale ha un'incidenza minima.",
        "Nel valutare i giocatori della tua rosa, considera anche l'aspetto mentale e comportamentale.",
        "Nel calcetto a 5 ed a 6, la tecnica individuale e la precisione nei passaggi sono fondamentali.",
        "Un ottimo giocatore di calcio a 11 non è automaticamente un ottimo giocatore di calcetto. E viceversa.",
        "Contrariamente al calcetto, nel calciotto e nel calcio a 11, mantenere il posizionamento dettato dal ruolo è d'obbligo.",
      ],
      resetData: false,
      validateBtn: true,
      successModal: false,
      loading: false,
      enabledSubmit: false,
      showOddsArea: false,
      oddsA: 0,
      oddsB: 0,
      max: 100,
    };
  },
  methods: {
    async grabPlayers() {
      try {
        const { data } = await fetch();
        this.playersDb = data;
      } catch (error) {
        console.log(error);
      }
    },
    changedVal(val, key, oldval) {
      if(!this.playerMoving) {
        if (key.includes("playerA")) {
          val == 0 ? (this.partialA -= oldval) : (this.partialA += val);
        } else {
          val == 0 ? (this.partialB -= oldval) : (this.partialB += val);
        }
      }
    },
    addedPlayer(player, key) {
      player.key = key;
      this.addedPlayers.push(player);
    },
    selectedPlayer(playerId, playerObj) {
      if (playerId.includes("playerA")) {
        this.selectedPlayerIdA = playerId;
        this.selectedPlayerObjA = playerObj;
      } else {
        this.selectedPlayerIdB = playerId;
        this.selectedPlayerObjB = playerObj;
      }
    },
    objFromKey(key, obj) {
      obj.counter = this.$refs[key][0].counter;
      obj.origrole = this.$refs[key][0].origRoleCounter;
      obj.outrole = this.$refs[key][0].outOfRole;
      obj.img = this.$refs[key][0].img;
      obj.player = this.$refs[key][0].player;
      obj.value = this.$refs[key][0].value;
    },
    switchPlayerData(playerIdA, playerIdB, playerObjA, playerObjB) {
      // Switching main values of the 2 players Vue Obj
      this.$refs[playerIdA][0].counter = playerObjB.counter;
      this.$refs[playerIdB][0].counter = playerObjA.counter;
      this.$refs[playerIdA][0].origRoleCounter = playerObjB.origrole;
      this.$refs[playerIdB][0].origRoleCounter = playerObjA.origrole;
      this.$refs[playerIdA][0].outOfRole = playerObjB.outrole;
      this.$refs[playerIdB][0].outOfRole = playerObjA.outrole;
      this.$refs[playerIdA][0].img = playerObjB.img;
      this.$refs[playerIdB][0].img = playerObjA.img;
      this.$refs[playerIdA][0].player = playerObjB.player;
      this.$refs[playerIdB][0].player = playerObjA.player;
      this.$refs[playerIdA][0].value = playerObjB.value;
      this.$refs[playerIdB][0].value = playerObjA.value;
    },
    switchPlayers() {
      this.$root.$emit("unselected-radio");
      this.switchPlayerData(
        this.selectedPlayerIdA,
        this.selectedPlayerIdB,
        this.selectedPlayerObjA,
        this.selectedPlayerObjB
      );
      // Subtracting value who switched team from (if there is difference between the 2)
      if (this.selectedPlayerObjA.value != this.selectedPlayerObjB.value) {
        this.partialA -= this.selectedPlayerObjA.value;
        this.partialB -= this.selectedPlayerObjB.value;
      }
      // Updating the selected Players copied Objects
      let temporaryMem = this.selectedPlayerObjB;
      this.selectedPlayerObjB = this.selectedPlayerObjA;
      this.selectedPlayerObjA = temporaryMem;
      // Hiding Stats in case they are visible...
      this.showOddsArea = false;
      // Clearing cached Switching players infos
      this.selectedPlayerIdA = "";
      this.selectedPlayerIdB = "";
      this.selectedPlayerObjA = {};
      this.selectedPlayerObjB = {};
    },
    async movePlayer(dir, key) {
      this.playerMoving = true;
      switch (dir) {
        // 1 is UP
        case 1:
          if (parseInt(key.replace(/^\D+/g, "")) == 1) {
            console.log("First Player!");
          } else {
            this.movingPlayerIdFrom = key;
            const keyDigit = parseInt(key.replace(/^\D+/g, "")) - 1;
            const keyText = key.replace(/[0-9]/g, "");
            this.movingPlayerIdTo = keyText + keyDigit;
            this.objFromKey(this.movingPlayerIdFrom, this.movingPlayerFrom);
            this.objFromKey(this.movingPlayerIdTo, this.movingPlayerTo);
            this.switchPlayerData(
              this.movingPlayerIdFrom,
              this.movingPlayerIdTo,
              this.movingPlayerFrom,
              this.movingPlayerTo
            );
            this.playerMoving = await false;
          }
          break;
        // 2 is DOWN
        case 2:
          if (parseInt(key.replace(/^\D+/g, "")) == this.playersNumber) {
            console.log("Last Player!");
          } else {
            this.movingPlayerIdFrom = key;
            const keyDigit = parseInt(key.replace(/^\D+/g, "")) + 1;
            const keyText = key.replace(/[0-9]/g, "");
            this.movingPlayerIdTo = keyText + keyDigit;
            this.objFromKey(this.movingPlayerIdFrom, this.movingPlayerFrom);
            this.objFromKey(this.movingPlayerIdTo, this.movingPlayerTo);
            this.switchPlayerData(
              this.movingPlayerIdFrom,
              this.movingPlayerIdTo,
              this.movingPlayerFrom,
              this.movingPlayerTo
            );
            this.playerMoving = await false;
          }
          break;
      }
    },
    removedPlayer(playerName) {
      this.addedPlayers = this.addedPlayers.filter(function (el) {
        return el.name != playerName;
      });
      this.enabledSubmit = false;
      this.showOddsArea = false;
    },
    setPlayers() {
      this.playersNumber = parseInt(event.target.value);
      this.resetPlayers();
    },
    resetPlayers() {
      this.resetData = true;
      this.addedPlayers = [];
      this.$root.$emit("unselected-radio");
      this.selectedPlayerIdA = "";
      this.selectedPlayerIdB = "";
      this.selectedPlayerObjA = {};
      this.selectedPlayerObjB = {};
      setTimeout(() => {
        this.partialA = 0;
        this.partialB = 0;
        this.resetData = false;
      }, 1000);
    },
    showModal() {
      this.successModal = true;
      setTimeout(() => {
        this.successModal = false;
      }, 3000);
    },
    saveLoading() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
    getOdds() {
      this.saveLoading();
      this.getStats();
      setTimeout(() => {
        this.showOddsArea = true;
        this.enabledSubmit = false;
      }, 3000);
    },
    randArray(array) {
      const item = array[Math.floor(Math.random() * array.length)];
      return item;
    },
    getStats() {
      // Teams overall difference
      this.matchStats.partialDiff = parseFloat(
        Math.abs(this.partialA - this.partialB)
      );
      // Detect which Team is stronger
      if (this.partialA == this.partialB) {
        this.matchStats.strongerTeam = "";
      } else if (this.partialA > this.partialB) {
        this.matchStats.strongerTeam = "A";
      } else {
        this.matchStats.strongerTeam = "B";
      }
      // Fill the sentence
      const diff = this.matchStats.partialDiff;
      const playersFactor = ((this.playersNumber * 1) / 6).toFixed(2);
      switch (true) {
        case diff == 0:
          this.matchStats.matchWord = "Perfettamente Equilibrate";
          break;
        case diff <= (1 * playersFactor).toFixed(2):
          this.matchStats.matchWord = "Equilibrate";
          break;
        case diff <= (1.5 * playersFactor).toFixed(2):
          this.matchStats.matchWord = "Leggermente Squilibrate";
          break;
        case diff <= (2.5 * playersFactor).toFixed(2):
          this.matchStats.matchWord = "Squilibrate";
          break;
        case diff <= (3.5 * playersFactor).toFixed(2):
          this.matchStats.matchWord = "Fortemente Squilibrate";
          break;
        case diff > (3.5 * playersFactor).toFixed(2):
          this.matchStats.matchWord = "Totalmente Squilibrate";
          break;
        default:
          this.matchStats.matchWord = "Errore di Calcolo";
      }
      // Odds calculation
      this.matchScore = this.partialA + this.partialB;
      if (this.partialA == this.partialB) {
        this.oddsA = 50;
        this.oddsB = this.oddsA;
      } else if (this.partialA > this.partialB) {
        const imbalance = parseInt(
          (this.partialA / this.matchScore - 0.5) * 500
        );
        this.oddsA = 50 + imbalance;
        this.oddsB = 100 - this.oddsA;
      } else {
        const imbalance = parseInt(
          (this.partialB / this.matchScore - 0.5) * 500
        );
        this.oddsB = 50 + imbalance;
        this.oddsA = 100 - this.oddsB;
      }
      // Match Quality
      this.matchStats.matchQuality = Math.round(
        (5 * (this.partialA + this.partialB)) / (3 * this.addedPlayers.length)
      );
    },
  },
  async created() {
    this.grabPlayers();
    this.$root.$on("fresh-players", () => {
      this.resetPlayers();
      this.grabPlayers();
    });
  },
  watch: {
    newPlayer() {
      if (this.newPlayer.name == undefined) {
        this.validateBtn = true;
      } else {
        this.validateBtn = false;
      }
    },
    data() {
      // user loggedin / register
      this.resetPlayers();
      this.grabPlayers();
    },
  },
  computed: {
    ...mapState("user", ["data"]),
    isTeamFull() {
      if (this.addedPlayers.length == this.playersNumber * 2) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.btn-success {
  background-color: #20c997 !important;
}
.btn-primary {
  background-color: #008bb7 !important;
}
.spinner-border-sm {
  width: 0.75rem !important;
  height: 0.75rem !important;
}
.team-box {
  background-color: white;
}
.players-num {
  font-size: 14px;
  font-weight: bold;
}
.sel-players option {
  text-align: center;
}
.add-hint {
  font-size: 12px;
  color: grey;
}
.partial-score {
  font-family: "Merriweather Sans", sans-serif;
  font-size: larger;
}
.partial-blue {
  color: #008bb7;
}
.partial-green {
  color: #20c997;
}
.hints,
.facts {
  color: grey;
}
.quality-star {
  color: rgb(255, 192, 3);
}
</style>