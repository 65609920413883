<template>
  <Modal
    ref="modal"
    referID="loginModal"
    title="Accedi"
    @submit="submit(login)"
    submitText="Accedi"
    :submitLoading="loading"
    :submitDisable="this.$v.form.$anyError"
    iconFontawesome="fa-sign-in-alt"
  >
    <Alert :text="errorLogin" />
    <form>
      <!-- Email input -->
      <Input
        v-model="form.email"
        type="text"
        placeholder="E-mail"
        note="Inserisci e-mail d'accesso."
        :vuelidate="$v.form.email"
        :showError="validationErr"
      />
      <!-- Password input -->
      <Input
        v-model="form.password"
        type="password"
        placeholder="Password"
        note="Inserisci password d'accesso."
        :vuelidate="$v.form.password"
        :showError="validationErr"
      />
    </form>
  </Modal>
</template>

<script>
import Alert from "@/components/UI/Alert.vue";
import Input from "@/components/UI/Input.vue";
import Modal from "../UI/Modal.vue";
import { mapState, mapActions } from "vuex";
import FormValidate from "@/mixins/form-validate.js";
import { login as validations } from "@/validations/auth.js";
export default {
  mixins: [FormValidate],
  data: () => ({
    loading: false,
    form: {
      email: "",
      password: "",
    },
  }),
  methods: {
    ...mapActions("user", ["LOGIN"]),
    async login() {
      this.loading = true;
      await this.LOGIN(this.form);
      if (!this.errorLogin) this.$refs.modal.close();
      this.loading = false;
    },
  },
  computed: {
    ...mapState("user", ["errorLogin"]),
  },
  validations: validations(),
  components: { Modal, Alert, Input },
};
</script>

<style>
button.btn-secondary {
  background-color: #a19da3 !important;
}
</style>