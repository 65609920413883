<template>
  <nav class="navbar navbar-light shadow-sm">
    <div class="bubbles">
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
      <div class="bubble"></div>
    </div>
    <div class="container d-flex justify-content-between">
      <a class="navbar-brand nav-block" href="#">
        <img
          src="https://i.imgur.com/RvbrM7O.png"
          alt="squad-wizard-logo"
          width="40"
          height="auto"
        />
        <span class="logo-text">SquadWizard</span>
      </a>
      <div v-if="!data" class="right-nav">
        <Button
          text="Accedi"
          class="me-2"
          modalID="loginModal"
          color="success"
          iconFontawesome="fa-sign-in-alt"
        />
        <Button
          text="Registrati"
          modalID="registerModal"
          color="light"
          iconFontawesome="fa-user-plus"
        />
        <!-- <i class="fas fa-question-circle me-2 shadow-sm" style="color: white; cursor: pointer;"></i> -->
      </div>
      <UserDropdown v-else />
    </div>
    <!-- Modals -->
    <LoginModal />
    <RegisterModal />
  </nav>
</template>

<script>
import Button from "@/components/UI/Button.vue";
import LoginModal from "@/components/auth/LoginModal.vue";
import RegisterModal from "@/components/auth/RegisterModal.vue";
import UserDropdown from "@/components/core/UserDropdown.vue";
import { mapState } from "vuex";
export default {
  name: "Navbar",
  props: {
    name: String,
  },
  computed: {
    ...mapState("user", ["data"]),
  },
  components: { Button, LoginModal, RegisterModal, UserDropdown },
};
</script>

<style scoped>
nav {
  background: rgb(0,139,183);
  background: linear-gradient(90deg, rgba(0,139,183,1) 0%, rgba(32,201,151,1) 100%);
  border-bottom: solid 2px rgb(32, 201, 151);
}
.bubbles {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble {
  position: absolute;
  bottom: -100px;
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  border-radius: 50%;
  opacity: 0.5;
  animation: rise 10s infinite ease-in;
}
.bubble:nth-child(1) {
  width: 20px;
  height: 20px;
  left: 10%;
  animation-duration: 8s;
}
.bubble:nth-child(2) {
  width: 10px;
  height: 10px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(3) {
  width: 30px;
  height: 30px;
  left: 35%;
  animation-duration: 7s;
  animation-delay: 2s;
}
.bubble:nth-child(4) {
  width: 35px;
  height: 35px;
  left: 50%;
  animation-duration: 11s;
  animation-delay: 0s;
}
.bubble:nth-child(5) {
  width: 25px;
  height: 25px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: 1s;
}
.bubble:nth-child(6) {
  width: 20px;
  height: 20px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: 3s;
}
.bubble:nth-child(7) {
  width: 30px;
  height: 30px;
  left: 70%;
  animation-duration: 12s;
  animation-delay: 2s;
}
.bubble:nth-child(8) {
  width: 25px;
  height: 25px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: 2s;
}
.bubble:nth-child(9) {
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 5s;
  animation-delay: 1s;
}
.bubble:nth-child(10) {
  width: 30px;
  height: 30px;
  left: 25%;
  animation-duration: 10s;
  animation-delay: 4s;
}
@keyframes rise {
  0% {
    bottom: -100px;
    transform: translateX(0);
  }
  50% {
    transform: translate(100px);
  }
  100% {
    bottom: 1080px;
    transform: translateX(-200px);
  }
}
.logo-text {
  color: white;
  font-family: "Berkshire Swash", cursive;
  text-shadow: 1px 1px 2px darkslategray;
  font-size: 1.3rem;
}
.nav-block, .right-nav {
  z-index: 5;
}
.btn-success {
  background-color: #20c997 !important;
}
.btn-primary {
  background-color: #008bb7 !important;
}
</style>