import { login, register, parseToken } from '@/services/auth.js';
export default {
	namespaced: true,
	state: {
		data: null,
		errorLogin: null,
		errorRegister: null
	},
	mutations: {
		SET_USER(state, payload) {
			state.data = payload;
		},
		SET_ERR_LOGIN(state, payload) {
			state.errorLogin = payload;
		},
		SET_ERR_REGISTER(state, payload) {
			state.errorRegister = payload;
		},
		LOGOUT(state) {
			localStorage.removeItem('token');
			state.data = null;
		}
	},
	actions: {
		async LOAD_USER({ commit }) {
			const token = localStorage.getItem('token');
			if (!token) return;
			const { data } = await parseToken(token);
			commit('SET_USER', data);
		},
		async REGISTER({ commit, dispatch }, payload) {
			try {
				await register(payload);
				await dispatch('LOGIN', { email: payload.email, password: payload.password });
			} catch (e) {
				e.response && commit('SET_ERR_REGISTER', e.response.data);
			}
		},
		async LOGIN({ commit }, payload) {
			try {
				const { data } = await login(payload);
				localStorage.setItem('token', data.token);
				commit('SET_USER', data.user);
				commit('SET_ERR_LOGIN', null);
			} catch (e) {
				commit('SET_ERR_LOGIN', e.response.data);
			}
		}
	}
};
