<template>
  <Modal
    ref="modal"
    referID="registerModal"
    title="Registrazione"
    submitText="Crea un account"
    @submit="submit(register)"
    :submitLoading="loading"
    :submitDisable="!samePass || this.$v.form.$anyError"
    iconFontawesome="fa-user-plus"
  >
    <Alert :text="errorRegister" />
    <form>
      <!-- FirstName input -->
      <Input
        v-model="form.first_name"
        type="text"
        placeholder="Nome"
        note="Inserisci il tuo nome."
        :vuelidate="$v.form.first_name"
        :showError="validationErr"
      />
      <!-- LastName input -->
      <Input
        v-model="form.last_name"
        type="text"
        placeholder="Cognome"
        note="Inserisci il tuo cognome."
        :vuelidate="$v.form.last_name"
        :showError="validationErr"
      />
      <!-- Email input -->
      <Input
        v-model="form.email"
        type="text"
        placeholder="E-mail"
        note="Inserisci la tua e-mail."
        :vuelidate="$v.form.email"
        :showError="validationErr"
      />
      <!-- Password input -->
      <Input
        v-model="form.password"
        type="password"
        placeholder="Password"
        note="Inserisci la password."
        :vuelidate="$v.form.password"
        :showError="validationErr"
      />
      <!-- Confirm Password input -->
      <Input
        v-model="confirmPass"
        type="password"
        placeholder="Password"
        note="Conferma Password"
        :errorNote="{ con: !samePass, text: 'La password non coincide!' }"
      />
    </form>
  </Modal>
</template>

<script>
import Alert from "@/components/UI/Alert.vue";
import Input from "@/components/UI/Input.vue";
import FormValidate from "@/mixins/form-validate.js";
import { mapState, mapActions } from "vuex";
import { register as validations } from "@/validations/auth.js";
import Modal from "../UI/Modal.vue";
export default {
  mixins: [FormValidate],
  data: () => ({
    loading: false,
    form: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    confirmPass: "",
  }),
  methods: {
    ...mapActions("user", ["REGISTER"]),
    async register() {
      this.loading = true;
      await this.REGISTER(this.form);
      if (!this.errorRegister) this.$refs.modal.close();
      this.loading = false;
    },
  },
  computed: {
    ...mapState("user", ["errorRegister"]),
    samePass() {
      return this.confirmPass == this.form.password;
    },
  },
  validations: validations(),
  components: { Modal, Input, Alert },
};
</script>

<style>
button.btn-secondary {
  background-color: #a19da3 !important;
}
.add-hint {
  font-size: 12px;
  color: grey;
}
.error-hint {
  font-size: 12px;
  color: red;
}
</style>