<template>
  <div>
    <img
      :src="pureSrc || profileImg"
      alt="player-img"
      class="ms-1 rounded player-img"
    />
  </div>
</template>

<script>
export default {
  props: ["src", "pureSrc"],
  computed: {
    profileImg() {
      return this.src
        ? this.$storage(`/images/${this.src}`)
        : "https://i.imgur.com/V8PrQ7N.png";
    },
  },
};
</script>

<style lang="scss" scoped>
.player-img {
  min-width: 38px;
  max-height: 38px;
}
</style>