import api from '@/plugins/axios.js';

const endpoint = '/players';
export const fetch = () => api().get(endpoint);
export const fetchMyPlayers = () => api().get(`${endpoint}/my`);
export const create = (player) => api().post(endpoint, player);
export const edit = (id, newData) => api().put(`${endpoint}/${id}`, newData);
export const remove = (id) => api().delete(`${endpoint}/${id}`);
export const attachProfileImage = (id, profileImg) => {
	const form = new FormData();
	form.append('file', profileImg);
	return api({ 'Content-Type': `multipart/form-data;` }).post(`${endpoint}/profile-img/${id}`, form);
};
