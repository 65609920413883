<template>
  <Modal
    ref="modal"
    referID="managePlayers"
    title="Gestisci Rosa"
    submitText="OK"
    @submit="ok"
    :submitLoading="loading"
    iconFontawesome="fa-users-cog"
  >
    <div class="heading-actions">
      <Button
        @click="openDynamicRow()"
        color="success"
        text="Aggiungi Gioc."
        classes="mb-2 btn-sm"
        iconFontawesome="fa-plus"
        :isDisabled="trueBool"
      />
    </div>
    <Alert :text="error" />
    <div class="content-table" v-if="!loading">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Foto</th>
            <th scope="col">Nome</th>
            <th scope="col">Valore</th>
            <th scope="col">Ruolo</th>
            <th scope="col">Azioni</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="player in players" :key="player._id">
            <th scope="row">
              <ProfileImage :src="player.profile_img" />
            </th>
            <th scope="row" v-text="player.name"></th>
            <td v-text="player.value" />
            <td v-text="player.role" />
            <td class="action-player">
              <Button
                @click="openDynamicRow(player)"
                color="primary"
                iconFontawesome="fa-pen"
                classes="btn-floating me-1 btn-sm"
                :isDisabled="trueBool"
              />
              <Button
                @click="remove(player._id)"
                color="danger"
                iconFontawesome="fa-trash"
                classes="btn-floating btn-sm"
                :isDisabled="trueBool"
              />
            </td>
          </tr>
          <!-- Add/Edit Player row -->
          <DynamicRow
            v-if="dynamicRow"
            @close="dynamicRow = false"
            @edit="update"
            @add="add"
            :data="player"
            :image="player && player.profile_img"
          />
        </tbody>
      </table>
    </div>
    <div v-else class="text-center">
      <div class="spinner-border my-5" role="status">
        <span class="visually-hidden">Attendi...</span>
      </div>
    </div>
  </Modal>
</template>

<script>
import Alert from "@/components/UI/Alert.vue";
import Button from "@/components/UI/Button.vue";
import {
  edit,
  remove as delPlayer,
  fetchMyPlayers,
  create,
  attachProfileImage,
} from "@/services/players.js";
import Modal from "@/components/UI/Modal.vue";
import DynamicRow from "./DynamicRow.vue";
import ProfileImage from "@/components/player/ProfileImage.vue";
export default {
  data: () => ({
    loading: true,
    players: null,
    dynamicRow: false,
    error: null,
    player: null,
    trueBool: true,
  }),
  mounted() {
    this.fetch();
    this.$root.$on("switch-crud", () => {
      this.trueBool = !(this.trueBool);
    });
  },
  methods: {
    // Fetch players
    async fetch() {
      this.loading = true;
      this.players = (await fetchMyPlayers()).data;
      this.$root.$emit("fresh-players");
      this.loading = false;
    },
    // Add new player
    async add(player) {
      this.loading = true;
      try {
        const { file, ...rest } = player;
        const { data } = await create(rest);
        if (player.file) await attachProfileImage(data._id, file);
        this.error = null;
      } catch (e) {
        this.error = "Faild to add a new player";
        console.log(e);
      }
      await this.fetch();
      this.loading = false;
    },
    // Update a player
    async update(player) {
      this.loading = true;
      try {
        const { file, ...rest } = player;
        const { data } = await edit(player._id, rest);
        if (player.file) await attachProfileImage(data._id, file);
        this.error = null;
      } catch (e) {
        this.error = "Faild to edit the player";
        console.log(e);
      }
      await this.fetch();
      this.loading = false;
      this.dynamicRow = false;
    },
    // Remove player
    async remove(id) {
      this.loading = true;
      await delPlayer(id);
      this.fetch();
      this.dynamicRow = false;
    },
    openDynamicRow(player = null) {
      this.player = player;
      this.dynamicRow = true;
    },
    ok() {
      this.$refs.modal.close();
    },
  },
  watch: {
    switchCrud() {
      this.$root.$on("switch-crud", () => {
        this.trueBool = !(this.trueBool);
      });
    }
  },
  components: { Modal, Alert, Button, DynamicRow, ProfileImage },
};
</script>

<style lang="scss" scopped>
#managePlayers {
  .content-table {
    width: 100%;
    overflow: auto;
  }
  .modal-dialog {
    max-width: 900px;
  }
  .heading-actions {
    text-align: right;
  }
  .action-player {
    white-space: nowrap;
  }
}
</style>