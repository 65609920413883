<template>
  <div class="player-box d-flex mb-3">
    <div
      class="player-selected d-flex align-items-center me-1"
      v-if="isTeamFull"
    >
      <input
        type="radio"
        :name="this.$vnode.key.slice(0, -1)"
        :id="this.$vnode.key"
        :checked="selectedRadio"
        @change="selectedPlayer"
      />
    </div>
    <button
      type="button"
      :class="['btn btn-sm shadow-sm w-25 p-0', `btn-${role[counter].class}`]"
      @click="changeRole()"
    >
      <span v-show="counter == 0"><i class="fas fa-sync"></i></span>
      <span v-show="counter == 1"><i class="fas fa-hand-paper"></i></span>
      <span v-show="counter == 2"><i class="fas fa-shield-alt"></i></span>
      <span v-show="counter == 3"><i class="fas fa-cog"></i></span>
      <span v-show="counter == 4"><i class="fas fa-futbol"></i></span>
      {{ role[counter].name }}
    </button>
    <ProfileImage :src="img" />
    <div class="mx-1 w-50 position-relative height-38">
      <div class="input-group h-100">
        <input
          type="text"
          class="form-control player-input h-100 position-relative"
          placeholder="Nome Gioc."
          autocomplete="nope"
          @focus="modal = true"
          v-model="player"
          v-on:blur="modal = false"
          :disabled="isDisabled"
        />
        <div class="remove-tab me-1 position-absolute" @click="emptyField">
          <i class="fas fa-times-circle remove-icon"></i>
        </div>
      </div>
      <div
        v-if="filteredPlayers && modal && player"
        class="bg-light w-100 position-absolute players-menu"
      >
        <ul class="list-unstyled mb-0">
          <li
            v-for="(filteredPlayer, index) in filteredPlayers.slice(0, 5)"
            :key="index"
            class="py-2 px-1 border player-item"
            @mousedown="setPlayer(filteredPlayer)"
          >
            <span
              :class="[
                'preview-role p-1',
                `bg-${roleColor(filteredPlayer.role)}`,
              ]"
              >{{ filteredPlayer.role }}</span
            >
            <span class="ms-1"
              >{{ filteredPlayer.name }} (<strong>{{
                filteredPlayer.value
              }}</strong
              >)</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="form-outline d-flex align-items-center w-25">
      <input
        type="text"
        :class="[
          'form-control text-center player-value h-100 border',
          this.$vnode.key.includes('playerA') ? 'player-green' : 'player-blue',
          this.outOfRole ? 'low-value' : '',
        ]"
        placeholder="Value"
        v-model="value"
        readonly
      />
    </div>
    <div class="arrow-anchor ms-1" v-show="isTeamFull && movingArrows">
      <span @click="movePlayer(1)" :class="[this.$vnode.key.endsWith('A1') || this.$vnode.key.endsWith('B1') ? 'disabled-arrow' : '']">
        <i class="fas fa-arrow-up"></i>
      </span>
      <span @click="movePlayer(2)" :class="[this.$vnode.key.endsWith(playersNumber) ? 'disabled-arrow' : '']">
        <i class="fas fa-arrow-down"></i>
      </span>
    </div>
  </div>
</template>

<script>
import ProfileImage from "@/components/player/ProfileImage.vue";
export default {
  name: "Player",
  props: {
    playersDb: Array,
    addedPlayers: Array,
    resetData: {
      type: Boolean,
      default: false,
    },
    isTeamFull: {
      type: Boolean,
      default: false,
    },
    playersNumber: {
      type: Number,
      default: 6
    },
    movingArrows: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedRadio: false,
      counter: 0,
      player: "",
      value: 0,
      oldValue: 0,
      img: null,
      modal: false,
      isDisabled: false,
      filteredPlayers: [],
      origRoleCounter: 0,
      outOfRole: false,
      role: [
        {
          name: "N/D",
          class: "secondary",
          icon: "fa-sync",
        },
        {
          name: "POR",
          class: "warning",
          icon: "fa-hand-paper",
        },
        {
          name: "DIF",
          class: "primary",
          icon: "fa-shield-alt",
        },
        {
          name: "CEN",
          class: "success",
          icon: "fa-cog",
        },
        {
          name: "ATT",
          class: "danger",
          icon: "fa-futbol",
        },
      ],
    };
  },
  methods: {
    changeRole() {
      if(this.value != 0) {
        this.counter++;
        if ((this.counter <= this.role.length - 1)) {
          if((this.counter != this.origRoleCounter) && !this.outOfRole) {
            this.outOfRole = true;
          } else if((this.counter == this.origRoleCounter)) {
            this.outOfRole = false;
          }
        } else {
          this.counter = 0;
          this.outOfRole = true;
        }
        // this.selectedPlayer();
      }
    },
    filterPlayers() {
      this.filteredPlayers = this.playersDb.filter((player) => {
        if (!this.addedPlayers.includes(player)) {
          return player.name.toLowerCase().includes(this.player.toLowerCase());
        }
      });
    },
    setPlayer(player) {
      this.$emit("added-player", player, this.$vnode.key);
      this.player = player.name;
      this.value = parseFloat(player.value);
      this.revertRole(player.role);
      this.origRoleCounter = this.counter;
      this.img = player.profile_img;
      this.modal = false;
      this.isDisabled = true;
    },
    revertRole(string) {
      switch (string) {
        case "N/D":
          this.counter = 0;
          break;
        case "POR":
          this.counter = 1;
          break;
        case "DIF":
          this.counter = 2;
          break;
        case "CEN":
          this.counter = 3;
          break;
        case "ATT":
          this.counter = 4;
          break;
      }
    },
    roleColor(role) {
      switch (role) {
        case "N/D":
          return "secondary";
        case "POR":
          return "warning";
        case "DIF":
          return "primary";
        case "CEN":
          return "success";
        case "ATT":
          return "danger";
      }
    },
    emptyField() {
      this.$emit("remove-player", this.player);
      this.counter = 0;
      this.player = "";
      this.oldValue = this.value;
      this.value = 0;
      this.img = null;
      this.isDisabled = false;
      this.outOfRole = false;
      this.origRoleCounter = 0;
    },
    selectedPlayer() {
      this.selectedRadio = true;
      const playerObj = {
        counter: this.counter,
        img: this.img,
        origrole: this.origRoleCounter,
        outrole: this.outOfRole,
        player: this.player,
        value: parseFloat(this.value),
      };
      this.$emit("selected-player", this.$vnode.key, playerObj);
    },
    movePlayer(direction) {
      this.$emit("move-player", direction, this.$vnode.key);
    }
  },
  watch: {
    player() {
      this.filterPlayers();
    },
    value() {
      this.$emit("val-change", this.value, this.$vnode.key, this.oldValue);
    },
    resetData() {
      this.counter = 0;
      this.player = "";
      this.value = 0;
      this.isDisabled = false;
      this.img = null;
      this.filteredPlayers = [];
      this.origRoleCounter = 0;
      this.outOfRole = false;
    },
    selectedRadio() {
      this.$root.$on("unselected-radio", () => {
        this.selectedRadio = false;
      });
    }
  },
  components: { ProfileImage },
};
</script>

<style scoped>
.btn-success {
  background-color: #20c997 !important;
}
.btn-primary {
  background-color: #008bb7 !important;
}
.form-outline .form-control {
  padding: 0 !important;
}
.player-input {
  font-size: 13px;
}
.player-value {
  font-family: "Merriweather Sans", sans-serif;
  font-size: 14px;
  font-weight: bolder;
  color: white;
  text-shadow: 1px 1px 1px grey;
}
.player-green {
  background-color: #20c997b3 !important;
}
.player-blue {
  background-color: #008bb7b3 !important;
}
.preview-role {
  border-radius: 4px;
  color: white;
  font-size: 10px;
}
.height-38 {
  height: 38px;
}
.players-menu {
  z-index: 20;
}
.player-item {
  font-size: 12px;
  cursor: pointer;
}
.remove-icon {
  color: grey;
  cursor: pointer;
  width: 0.75rem;
}
.remove-tab {
  right: 0;
  top: 0;
}
button.btn-secondary {
  background-color: #a19da3 !important;
}
.player-selected input {
  cursor: pointer;
}
.low-value {
  background-color: #f80c35 !important;
}
.arrow-anchor {
  font-size: 12px;
  cursor: pointer;
  color: grey;
}
.disabled-arrow {
  color: #cecece !important;
}
</style>