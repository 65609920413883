import axios from 'axios';
import Vue from 'vue';

const defaultServer = 'http://localhost:3000';
const url = process.env.VUE_APP_SERVER || defaultServer;
const storage = process.env.VUE_APP_SERVER_STORAGE || `${defaultServer}/storage`;

const setHeaders = (customHeaders = {}) => {
	const token = localStorage.getItem('token');
	return token
		? {
				headers: {
					Authorization: `Bearer ${token}`,
					...customHeaders
				}
			}
		: {};
};

const instance = (customHeaders) =>
	axios.create({
		baseURL: `${url}/api/`,
		...setHeaders(customHeaders)
	});

Vue.prototype.$api = instance;
Vue.prototype.$storage = (path) => `${storage}${path}`;

export default instance;
