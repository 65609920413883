<template>
  <button
    ref="btn"
    :class="{
      btn: true,
      [`btn-${color}`]: true,
      [classes]: classes,
    }"
    :disabled="isDisabled || loading"
    @click="$emit('click')"
  >
    <div
      v-if="loading"
      class="spinner-border spinner-border-sm me-2"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <i
      v-show="iconFontawesome && !loading"
      :class="`fas ${iconFontawesome}`"
    ></i>
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      default: "primary",
    },
    text: {
      type: String,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    iconFontawesome: {
      type: String,
    },
    modalID: {
      type: String,
    },
    classes: {
      type: String,
    },
  },
  mounted() {
    if (this.modalID) {
      this.$refs.btn.setAttribute("data-mdb-toggle", "modal");
      this.$refs.btn.setAttribute("data-mdb-target", "#" + this.modalID);
    }
  },
};
</script>

<style>
</style>