<template>
  <div v-if="text" class="alert alert-danger" role="alert" v-text="text" />
</template>

<script>
export default {
  props: {
    text: String,
  }
}
</script>

<style>

</style>