<template>
  <div>
    <div class="wrapper-img" v-if="current || previewImage" @click="chooseFile">
      <ProfileImage :src="current" :pureSrc="previewImage" />
    </div>
    <Button
      v-else
      @click="chooseFile"
      iconFontawesome="fa-upload"
      classes="btn-floating btn-sm ms-2"
    />
    <input
      ref="fileUpload"
      accept="image/*"
      @change="update"
      type="file"
      hidden
    />
  </div>
</template>

<script>
import ProfileImage from "@/components/player/ProfileImage.vue";
import Button from "@/components/UI/Button.vue";
export default {
  props: ["value", "current"],
  data: () => ({
    previewImage: null,
  }),
  watch: {
    current() {
      this.previewImage = null;
    },
  },
  methods: {
    update(e) {
      const file = e.target.files[0];
      this.previewImage = URL.createObjectURL(file);
      this.$emit("input", file);
    },
    chooseFile() {
      this.$refs.fileUpload.click();
    },
  },
  components: { Button, ProfileImage },
};
</script>

<style lang="scss" scoped>
.wrapper-img {
  cursor: pointer;
}
</style>