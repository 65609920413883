import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import store from '@/store/index.js';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(async (to, from, next) => {
	await store.dispatch('user/LOAD_USER');
	next();
});

export default router;
