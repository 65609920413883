import { validationMixin } from 'vuelidate';
export default {
	data: () => ({
		validationErr: false
	}),
	mixins: [ validationMixin ],
	methods: {
		submit(run) {
			this.$v.form.$touch();
			if (this.$v.form.$anyError) {
				this.validationErr = true;
				return;
			}
			run();
		}
	}
};
