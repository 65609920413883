import Vue from 'vue'
import VueHead from 'vue-head'
import VueRouter from 'vue-router'
import { BProgress } from 'bootstrap-vue'
import { BProgressBar } from 'bootstrap-vue'
import './plugins/axios.js'

Vue.component('b-progress-bar', BProgressBar)
Vue.component('b-progress', BProgress)
Vue.use(VueRouter)
Vue.use(VueHead)

import App from './App.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import router from './router'
import store from './store'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
