<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-light dropdown-toggle"
      data-mdb-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="far fa-user"></i> 
      {{ data.first_name }} {{ data.last_name }}
    </button>
    <ul class="dropdown-menu text-left">
      <li class="border-bottom">
        <Button text="Gestisci Rosa" color="link" modalID="managePlayers" iconFontawesome="fa-users-cog" />
      </li>
      <li>
      </li>
      <li><Button @click="LOGOUT" text="Esci" color="link" iconFontawesome="fa-sign-out-alt"/></li>
    </ul>
    <!-- Modal -->
    <ManagePlayersModal />
  </div>
</template>

<script>
import ManagePlayersModal from "@/components/manage-players/Crud";
import Button from "@/components/UI/Button.vue";
import { mapState, mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations("user", ["LOGOUT"]),
  },
  computed: {
    ...mapState("user", ["data"]),
  },
  components: { Button, ManagePlayersModal },
};
</script>

<style lang="scss" scoped>
.dropdown-menu {
  > li {
    button {
      color: #008bb7 !important;
    }
    > * {
      width: 100%;
      height: 100%;
    }
  }
}
</style>